import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/branding',
    name: 'branding',
    component: () => import('../views/BrandingView.vue')
  },
  {
    path: '/work',
    name: 'work',
    component: () => import('../views/OurWork.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/growth-hacking',
    name: 'GrowthHacking',
    component: () => import('../views/GrowthHacking.vue')
  },
  {
    path: '/crypto-marketing',
    name: 'CryptoMarketing',
    component: () => import('../views/CryptoMarketing.vue')
  },
  {
    path: '/development',
    name: 'DevelopmentView',
    component: () => import('../views/DevelopmentView.vue')
  },
  {
    path: '/press-release',
    name: 'PressRelease',
    component: () => import('../views/PressRelease.vue')
  },
  {
    path: '/Brand-reputation',
    name: 'BrandReputation',
    component: () => import('../views/BrandReputation.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // Add the scrollBehavior function here
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router
